<template>
  <Head>
    <meta
      v-if="blog && blog.meta_description !== null"
      name="description"
      :content="blog.meta_description"
    />
    <meta
      v-else
      name="description"
      content="VB Live is the Best Online Coaching Platform for NEET, IIT-JEE, CBSE, Junior IAS Initiative and CA Foundation. We provides all Courses from Class 6 to Class 12 with Experienced Faculty."
    />
    <meta
      v-if="blog && blog.meta_keywords !== null"
      name="keywords"
      :content="blog.meta_keywords"
    />
    <meta
      v-else
      name="keywords"
      content="online training, courses online, online training courses, online tuition, online tuition classes for class 10, best online tuition for class 10, Best online tuition, best courses online, best online training, online tuition classes for class 12, best online tuition for class 12, best online coaching, ias online classes"
    />
  </Head>
  <div class="blog-listing">
    <div class="head-title">
      <div class="container">
        <h5 class="color-1">Blog</h5>
      </div>
    </div>
    <div v-if="blogLoading" class="loading">Loading...</div>
    <div v-else class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="blog-single row">
            <div class="img-wrap">
              <img v-if="blog && blog.image != null" :src="blog.image" alt="" />
              <img v-else src="../assets/thumb.png" alt="" />
            </div>
            <div class="blog-body">
              <h5 v-if="blog">{{ blog.title }}</h5>
              <b v-if="blog"> {{ blog.short_description }} </b><br />
              <p v-if="blog" v-html="blog.content"></p>
              <div class="info-row">
                <p class="credit" v-if="blog">by {{ blog.author }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div v-for="innerBlog in innerBlogs" :key="innerBlog.id">
            <router-link
              :to="{
                name: 'BlogSingle',
                params: { slug: innerBlog.slug },
              }"
              class="blog-card thumbnail"
            >
              <div class="img-wrap">
                <img
                  v-if="innerBlog.image != null"
                  :src="innerBlog.image"
                  alt=""
                />
                <img v-else src="../assets/thumb.png" alt="" />
              </div>
              <div class="blog-body">
                <h5>{{ innerBlog.title }}</h5>
                <div class="info-row">
                  <p class="credit">by {{ innerBlog.author }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonService from "@/services/CommonService";
import { Head } from "@vueuse/head";
export default {
  name: "BlogSingle",
  components: {
    Head,
  },
  data() {
    return {
      blogLoading: true,
      blog: null,
      innerBlogs: [],
    };
  },
  created() {
    this.fetchBlogDetails();
    this.fetchInnerBlogs();
  },
  watch: {
    "$route.params.slug": function () {
      if (
        this.$route.params.slug != undefined &&
        this.$route.name == "BlogSingle"
      ) {
        this.blogLoading = true;
        this.fetchBlogDetails();
        this.fetchInnerBlogs();
      }
    },
  },
  methods: {
    async fetchBlogDetails() {
      let params = {
        slug: this.$route.params.slug,
      };
      await CommonService.getBlogDetails(params)
        .then((response) => {
          this.blogLoading = false;
          if (response.data.status == "SUCCESS") {
            this.blog = response.data.blog;
          } else if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          } else if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async fetchInnerBlogs() {
      let params = {
        slug: this.$route.params.slug,
        take: "3",
      };
      await CommonService.getInnerBlogs(params)
        .then((response) => {
          // console.log(response.data.innerBlogs);
          this.blogLoading = false;
          if (response.data.status == "SUCCESS") {
            this.innerBlogs = response.data.innerBlogs;
          } else if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/content-page.scss";
</style>
